import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
    grid-column: 2/3;
    margin-bottom: 70px;
    h2 {
        font-family: 'Unna';
        font-size: 2.8rem;
        line-height: 3.5rem;
    }
    h3 {
        font-family: 'Unna';
        font-size: 2rem;
        line-height: 2.8rem;
    }
    p,
    li {
        font-size: 1.4rem;
        line-height: 2.4rem;
        font-family: 'Heebo';
        font-weight: 300;
    }
    li {
        padding-left: 30px;
        position: relative;
        &:before {
            content: '';
            display: block;
            position: absolute;
            left: 0;
            top: 10px;
            width: 3px;
            height: 3px;
            background-color: white;
        }
    }
    a {
        font-weight: bold;
    }
    @media screen and (min-width: 800px) {
        grid-column: 5/11;
        margin-bottom: 140px;
        h2 {
            font-family: 'Unna';
            font-size: 3.5rem;
            line-height: 5rem;
        }
        h3 {
            font-size: 2.5rem;
            line-height: 3.2rem;
        }
        p,
        li {
            font-size: 1.4rem;
            line-height: 2.4rem;
            font-family: 'Heebo';
        }
    }
`;

const Content = ({ data, className }) => {
    return (
        <Wrapper className={`${className} ct`}>
            <div dangerouslySetInnerHTML={{ __html: data }}></div>
        </Wrapper>
    );
};

export default Content;
