import { graphql } from 'gatsby';
import React from 'react';
import Content from '../components/common/Content';
import Header from '../components/common/Header';
import Layout from '../components/layout';
import SEO from '../components/SEO/SEO';

export const query = graphql`
    query($lang: WpLanguageCodeEnum) {
        allWpPage(
            filter: {
                language: { code: { eq: $lang } }
                template: { templateName: { eq: "Legals" } }
            }
        ) {
            edges {
                node {
                    seo {
                        description: metaDesc
                        title
                    }
                    acfPageLegals {
                        content
                        header {
                            subtitle
                            title
                            type
                        }
                    }
                }
            }
        }
    }
`;

const Legals = ({ data, pageContext }) => {
    const seo = data.allWpPage.edges[0].node.seo;
    data = data.allWpPage.edges[0].node.acfPageLegals;

    return (
        <Layout
            lang={pageContext.lang}
            pathTranslatePage={pageContext.pathTranslatePage}
        >
            <SEO
                lang={seo.lang}
                title={seo.title}
                description={seo.description}
                translations={pageContext.translations}
            />
            <Header data={data.header} />
            <Content data={data.content} />
        </Layout>
    );
};

export default Legals;
